import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import SEO from "./../components/seo"
import Layout from "../components/layout"
import { Link, navigate } from "gatsby"
import { inscription } from "./../actions/auth"
import { clearLoading } from "../actions"
import { useDispatch, useSelector } from "react-redux"

const Inscription = () => {
  const dispatch = useDispatch()
  const identifiants = useSelector(s => s.identifiants)
  const panier = useSelector(s => s.panierR)
  const loading = useSelector(s => s.loadingR)

  const [form, setForm] = useState({
    lastname: "",
    firstname: "",
    email: "",
    password: "",
    id_gender: "1",
    reponse: null,
  })
  const [quest, setQuest] = useState(null)
  useEffect(() => {
    dispatch(clearLoading())
    const quest = generateRandomQuestion()
    setQuest(quest)
  }, [])
  const generateRandomQuestion = () => {
    const types = [
      { type: "nb", quest: "Combien de lettres y-a-t'il dans le mot «{xxx}» ?" },
      { type: "pos", quest: "Quelle est la {x} lettre du mot «{xxx}» ?" },
    ]

    const words = ["cadre", "encadrement", "tableau", "sublimation", "vitrine", "doublo"]
    const type = types[rnd(0, types.length - 1)]
    const word = words[rnd(0, words.length - 1)]
    const letterPos = rnd(0, word.length - 1)
    const fr = ["première", "deuxième", "troisième", "quatrième", "cinquième", "sixème", "septième", "huitième", "neuvième", "dixième", "onzième"]

    switch (type.type) {
      case "nb":
        return { question: type.quest.replace("{xxx}", word), reponse: word.length }
      case "pos":
        return { question: type.quest.replace("{xxx}", word).replace("{x}", fr[letterPos]), reponse: word[letterPos] }
    }
  }

  const rnd = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

  const handleUpdate = event => {
    if (event.target.name === "firstname" || event.target.name === "lastname") {
      setForm({ ...form, [event.target.name]: event.target.value.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ ]/g, "") })
    } else {
      setForm({ ...form, [event.target.name]: event.target.value })
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (form.reponse.toLocaleLowerCase() === quest.reponse.toString().toLocaleLowerCase()) {
      dispatch(inscription(form))
    } else {
      alert("La réponse à la question n'est pas bonne")
    }
  }

  if (identifiants.identifiants != null) {
    if (typeof window !== "undefined") {
      if (panier.products.length >= 1) {
        navigate(`/apps/commande`)
      } else {
        navigate(`/`)
      }
    }
  }
  return (
    <>
      <Layout banner={false} location={{ pathname: "/" }}>
        <SEO
          title="Inscription"
          description="Créer un compte chez les professionnels du cadre pour commander"
          canonical={process.env.GATSBY_URL_ACTUEL + "/inscription/"}
        />
        <h1>Inscription</h1>
        <div className="columns is-centered login">
          <div className="section column is-half couleurShadow">
            <div className="h3 dotted">
              <svg>
                <use xlinkHref="#connexion" />
              </svg>
              Créez votre compte{" "}
            </div>
            <p>
              Vous avez déjà un compte&nbsp;?&nbsp;
              <Link to="/connexion/">Connectez-vous&nbsp;!</Link>
            </p>
            <form method="post" onSubmit={event => handleSubmit(event)}>
              <div className="field">
                <label className="label" htmlFor="id_gender">
                  Titre&nbsp;:
                </label>
                <div className="control">
                  <label className="radio">
                    <input type="radio" id="id_gender" name="id_gender" value="1" defaultChecked={form.id_gender == "1" ? true : false} />
                    &nbsp;M
                  </label>
                  <label className="radio">
                    <input type="radio" id="id_gender" name="id_gender" value="2" defaultChecked={form.id_gender == "2" ? true : false} />
                    &nbsp;Mme
                  </label>
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="firstname">
                  Prénom&nbsp;:
                </label>
                <div className="control">
                  <input type="text" id="firstname" name="firstname" onChange={e => handleUpdate(e)} required className="input" placeholder="Votre prénom" />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="lastname">
                  Nom&nbsp;:
                </label>
                <div className="control">
                  <input type="text" id="lastname" name="lastname" onChange={e => handleUpdate(e)} required className="input" placeholder="Votre nom" />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="email">
                  Adresse email&nbsp;:
                </label>
                <div className="control">
                  <input type="email" id="email" name="email" onChange={e => handleUpdate(e)} required className="input" placeholder="Votre adresse email" />
                </div>
                {loading.status === false && (
                  <div className="notification is-danger" style={{ marginTop: "1rem" }}>
                    L&apos;adresse email est déjà utilisée, veuillez en choisir une autre ou <Link to={`/connexion/`}>vous connecter</Link>
                  </div>
                )}
              </div>

              <div className="field">
                <label className="label" htmlFor="password">
                  Mot de passe&nbsp;:
                </label>
                <div className="control">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    onChange={e => handleUpdate(e)}
                    required
                    className="input"
                    placeholder="Votre mot de passe"
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="reponse">
                  {quest !== null && quest.question}
                </label>
                <div className="control">
                  <input className="input" name="reponse" id="reponse" type="text" required onChange={e => handleUpdate(e)} style={{ width: "20%" }} />
                </div>
              </div>

              <div className="field is-grouped" style={{ marginTop: "2rem" }}>
                <div className="control">
                  <input type="submit" className="button is-primary" value="Enregistrer" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    </>
  )
}

Inscription.propTypes = {
  location: PropTypes.object,
}

export default Inscription
